import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import siteTitleChars from '../utils/siteTitleChars'
import Header from './header'
import Triptych from './triptych'
import Footer from './footer'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wordpressWpApiMenusMenusItems(slug: { eq: "main-navigation" }) {
          items {
            title
            object_id
            object_slug
            url
            wordpress_children {
              title
              object_id
              object_slug
              url
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={siteTitleChars(
            'Loyalty & Reward Marketing Agency - Incremental'
          )}
          meta={[
            {
              name: 'description',
              content:
                '21 Years of Industry-Leading Experience in Loyalty Programs, Reward Programs, Sales Incentives Programs and Digital Strategy that Drive Sales for Corporations and Enterprises.',
            },
            {
              name: 'keywords',
              content:
                'incremental, loyalty program, incentive program, digital strategy',
            },
          ]}
          encodeSpecialCharacters={false}
        >
          <html lang="en" />
        </Helmet>
        <Header
          menu={data.wordpressWpApiMenusMenusItems.items}
          siteTitle={data.site.siteMetadata.title}
        />
        <div>{children}</div>
        <Triptych />
        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
