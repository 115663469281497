import styled from 'styled-components'

const LayoutCentred = styled.div`
  padding-bottom: 1rem;
  padding-top: 2rem;
  & > div {
    margin: 0 auto;
    max-width: 780px;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    @media (min-width: 780px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  p,
  ul,
  ol,
  table {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    @media (min-width: 780px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  ul,
  ol {
    margin-left: 1.6rem;
    margin-right: 0.6rem;
    @media (min-width: 780px) {
      margin-right: 0;
    }
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.85028rem;
  }
`

export default LayoutCentred
