import styled from 'styled-components'

const LayoutColThree = styled.div`
  padding-bottom: 1rem;
  padding-top: 1rem;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 2rem auto;
    max-width: 1200px;
    width: 100%;
    @media (min-width: 700px) {
      flex-direction: row;
    }
    & > div {
      width: 100%;
      @media (min-width: 700px) {
        width: 32%;
      }
      img {
        height: auto;
        width: 100%;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    @media (min-width: 780px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  p,
  ul,
  ol,
  table {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    @media (min-width: 780px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.85028rem;
  }
`

export default LayoutColThree
