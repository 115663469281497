import React from 'react'

import VideoContent from '../styled_components/style-video'

export default ({ node }) => (
  <VideoContent className={`bg--${node.background_colour}`}>
    <div className="video__wrapper">
      <div
        className="embed-container"
        dangerouslySetInnerHTML={{ __html: node.video }}
      />
    </div>
    {/* <h3>
      <em>{node.caption}</em>
    </h3> */}
  </VideoContent>
)
