import React from 'react'

import LayoutColFour from '../styled_components/style-col_four'

export default ({ node }) => (
  <LayoutColFour className={`boxes_four bg--${node.background_colour}`}>
    <div>
      <div dangerouslySetInnerHTML={{ __html: node.text }} />
      <div dangerouslySetInnerHTML={{ __html: node.text_2 }} />
      <div dangerouslySetInnerHTML={{ __html: node.text_3 }} />
      <div dangerouslySetInnerHTML={{ __html: node.text_4 }} />
    </div>
  </LayoutColFour>
)
