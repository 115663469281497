import styled from 'styled-components'

const StandoutQuote = styled.div`
  display: flex;
  justify-content: center;
  .standoutquote__bg {
    align-items: center;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 3rem;
    width: 100%;
  }
  .standoutquote__content {
    color: #fff;
    display: inline;
    font-size: 2.6rem;
    font-variation-settings: 'wght' 675;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    padding: 0;
    width: 100%;
    @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-weight: bold;
    }
  }
  /* This styles the three points on the homepage. Can be used elsewhere if needed */
  .standout__threesteps {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0;
    width: 100%;
    @media (min-width: 700px) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-around;
    }
    li {
      border-top: 12px solid #fff;
      color: #fff;
      font-size: 1.5rem;
      font-weight: normal;
      margin-bottom: 3rem;
      padding-top: 1.4rem;
      text-align: center;
      width: 96%;
      @media (min-width: 700px) {
        margin-bottom: 0;
        width: 30%;
      }
    }
  }
  .standout__threesteps > .standout__threesteps--one {
    border-top-color: #87b1c3;
  }
  .standout__threesteps > .standout__threesteps--two {
    border-top-color: #bfd230;
  }
  .standout__threesteps > .standout__threesteps--three {
    border-top-color: #f4bd19;
  }
`

export default StandoutQuote
