import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

const TriptychBg = styled.section`
  background-color: #131d28;
  color: #fff;
  padding-bottom: 0;
  padding-top: 2rem;
`

const TriptychContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.0875rem;
  @media (min-width: 700px) {
    flex-direction: row;
  }
  h3 {
    color: #f4bd19;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  a {
    color: #fff;
    font-weight: normal;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: #fff;
    }
  }
  ul {
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;
    li {
      line-height: 1.6;
      margin: 0;
      padding: 0;
    }
  }
`
const TriptychLeft = styled.div`
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
    width: 25%;
  }
`
const TriptychRight = styled.div`
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
    width: 25%;
  }
`

const Triptych = () => (
  <StaticQuery
    query={graphql`
      query TriptychQuery {
        wordpressWpApiMenusMenusItems(slug: { eq: "footer-services" }) {
          items {
            title
            object_id
            object_slug
            url
          }
        }
        allWordpressPost {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <TriptychBg>
        <TriptychContainer>
          <TriptychLeft>
            <h3>Services</h3>
            <ul>
              {data.wordpressWpApiMenusMenusItems.items.map(item => (
                <li key={item.object_id} className="footer__nav__item">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                </li>
              ))}
            </ul>
          </TriptychLeft>
          <TriptychRight>
            <h3>Contact us</h3>
            <p>
              IMG Sales Programs Ltd
              <br />
              Belmont House, Shrewsbury Business Park
              <br />
              Shrewsbury, Shropshire, United Kingdom, SY2 6LG
            </p>
            <p>
              Phone
              <br />
              <a className="phone" href="tel:07462292015">
                074 6229 2015
              </a>
            </p>
            <p>
              <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#105;&#109;&#103;&#115;&#097;&#108;&#101;&#115;&#046;&#099;&#111;&#046;&#117;&#107;">
                Email us
              </a>
            </p>
          </TriptychRight>
        </TriptychContainer>
      </TriptychBg>
    )}
  />
)

export default Triptych
