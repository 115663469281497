import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const imgSalesLogo = require('../images/logo-img-sales@2x.png')

const iconPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <defs>
      <style>{'.a{fill: #fff;} .b{fill: #f4bd19;fill-rule:evenodd;}'}</style>
    </defs>
    <title>icon-phone</title>
    <path
      className="a"
      d="M93.24,95.74a32.37,32.37,0,0,1-32.53-.25q-4.66-2.73-9.29-6A170.87,170.87,0,0,1,29.35,70.73,155.83,155.83,0,0,1,4.39,39,32.2,32.2,0,0,1,4,7.31,24.38,24.38,0,0,1,8,1.74Q12.6-2.83,20,4.59q14.68,14.69,6.57,24.35l-1.47,1.81Q19,38.27,40.41,59.67t28.85,15.2l1.81-1.47q9.77-8,24.36,6.56c2.48,2.48,3.95,4.72,4.41,6.74A5.33,5.33,0,0,1,98.28,92,23.68,23.68,0,0,1,93.24,95.74Z"
    />
    <path
      className="b"
      d="M49.89,5.85A48.74,48.74,0,0,1,94.17,50.13a3.87,3.87,0,0,1-3.84,4.18h-.09a3.87,3.87,0,0,1-3.89-3.51A40.73,40.73,0,0,0,49.22,13.69,3.86,3.86,0,0,1,45.7,9.84V9.71A3.86,3.86,0,0,1,49.89,5.85Z"
    />
    <path
      className="b"
      d="M50.09,21.63a33.06,33.06,0,0,1,28.29,28.3,3.87,3.87,0,0,1-3.82,4.38h-.11a3.89,3.89,0,0,1-3.87-3.38A25.19,25.19,0,0,0,49.1,29.46a3.9,3.9,0,0,1-3.4-3.85v-.14A3.88,3.88,0,0,1,50.09,21.63Z"
    />
  </svg>
)

const HeaderBg = styled.div`
  background-color: #131d28;
  border-bottom: 4px solid #f4bd19;
  #togglenav {
    display: none;
    position: absolute;
    right: 0;
    z-index: 10;
    &:checked + .header__container {
      .header__menu {
        right: 0 !important;
      }
      .overlay {
        background-color: #131d28;
        left: 0;
        right: 0;
      }
      .overlay__close {
        display: block;
        right: 290px;
      }
      .btn__close {
        display: block;
        right: 0;
        top: 1rem;
      }
    }
  }
`
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.1rem 0 1.1rem;
  @media (min-width: 920px) {
    padding: 0.5rem 1.1rem 0 1.1rem;
  }
`
const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (min-width: 920px) {
    flex-direction: column;
  }
`
const HeaderLogo = styled.div`
  align-items: center;
  display: flex;
  margin: 0;
  padding-top: 0.4rem;
  img {
    max-width: 270px;
    margin: 0.4rem 0;
    width: 40vw;
  }
`
const HeaderPhone = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.6rem 0 0.75rem;
  text-align: right;
  @media (min-width: 920px) {
    justify-content: flex-end;
    margin-right: 0;
  }
`
const HeaderPhoneIcon = styled.div`
  display: inline-block;
  height: 36px;
  margin-right: 1rem;
  vertical-align: middle;
  width: 36px;
  @media (min-width: 920px) {
    margin-right: 0;
  }
`
const HeaderPhoneNumber = styled.div`
  display: none;
  @media (min-width: 920px) {
    color: #fff;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 0.75rem;
    vertical-align: middle;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`

const Header = ({ siteTitle, menu }) => (
  <HeaderBg>
    <input type="checkbox" id="togglenav" name="" value="" />
    <HeaderContainer className="header__container">
      <HeaderLogo>
        <Link to="/">
          <img src={imgSalesLogo} title={siteTitle} alt={siteTitle} />
        </Link>
      </HeaderLogo>
      <HeaderRight>
        <HeaderPhone>
          <HeaderPhoneIcon>
            <a href="tel:07462292015">{iconPhone()}</a>
          </HeaderPhoneIcon>
          <HeaderPhoneNumber>
            <a href="tel:07462292015">074 6229 2015</a>
          </HeaderPhoneNumber>
        </HeaderPhone>
      </HeaderRight>
    </HeaderContainer>
  </HeaderBg>
)

export default Header
