import React from 'react';

import LayoutColThree from '../styled_components/style-col_three';

export default ({ node }) => (
  <LayoutColThree className={`bg--${node.background_colour}`}>
    <div>
      <div dangerouslySetInnerHTML={{ __html: node.text }} />
      <div dangerouslySetInnerHTML={{ __html: node.text_2 }} />
      <div dangerouslySetInnerHTML={{ __html: node.text_3 }} />
    </div>
  </LayoutColThree>
);
