import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

const FooterBg = styled.div`
  background-color: #54707c;
  color: #fff;
  padding-top: 1.4rem;
`

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0px 1.0875rem 1.45rem;
  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: center;
  }
`

const FooterCopyright = styled.div`
  font-size: 0.75rem;
  line-height: 1.1;
  padding: 7px 0 2px;
  text-align: center;
`

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        wordpressWpApiMenusMenusItems(slug: { eq: "footer" }) {
          items {
            title
            object_id
            object_slug
            url
          }
        }
      }
    `}
    render={data => (
      <FooterBg>
        <FooterContainer>
          <FooterCopyright className="footer__copyright">
            &copy; Copyright IMG Sales Programs Ltd 2023
          </FooterCopyright>
        </FooterContainer>
      </FooterBg>
    )}
  />
)

export default Footer
