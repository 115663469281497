import React from 'react'

import StandoutQuote from '../styled_components/style-standout_quote'

export default ({ node }) => (
  <StandoutQuote className={`standoutquote bg--${node.background_colour}`}>
    <div
      className="standoutquote__bg"
      style={{
        backgroundImage: `url('${node.quote_background_image.source_url}')`,
      }}
    >
      <div
        className="standoutquote__content"
        dangerouslySetInnerHTML={{ __html: node.quote_text }}
      />
    </div>
  </StandoutQuote>
)
