import styled from 'styled-components'

const VideoContent = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  padding-top: 1rem;
  .video__wrapper {
    max-width: 780px;
    width: 100%;
  }
  .embed-container {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default VideoContent
