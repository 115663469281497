import React from 'react'

import LayoutColTwo from '../styled_components/style-col_two'

export default ({ node }) => (
  <LayoutColTwo className={`bg--${node.background_colour}`}>
    <div>
      <div dangerouslySetInnerHTML={{ __html: node.text }} />
      <div dangerouslySetInnerHTML={{ __html: node.text_2 }} />
    </div>
  </LayoutColTwo>
)
